 /* ========= PACKAGE IMPORTS ========= */
import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import { Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { usePageVisibility } from 'react-page-visibility';
import platform from 'platform';
import { useTranslation } from 'react-i18next';


/* ========= CSS IMPORTS ========= */
import './App.scss';
import { Button, Container } from 'react-bootstrap';

/* ========= COMPONENT IMPORTS ========= */
import NavBar from './NavBar/NavBar';
import AboutModal from '../AboutModal/AboutModal';
import NetworkDetector from '../../components/NetworkDetector/NetworkDetector';
import Subscriptions from '../Subscriptions/Subscriptions';
import IncidentDetails from '../IncidentDetails/IncidentDetails';
import BackButton from '../../components/BackButton/BackButton';
import Footer from './Footer/Footer';
import ServiceAdvisoryDetails from '../ServiceAdvisories/ServiceAdvisoryDetails/ServiceAdvisoryDetails';
import VerifyMe from '../VerifyMe/VerifyMe';
import StatusHistory from '../StatusHistory/StatusHistory';
import Authenticate from '../Auth/Authenticate';
import NotFound from '../NotFound/NotFound';

/* ========= CUSTOM HOOKS ========= */
import { useWindowSize, useQuery } from '../../utils/customHooks';


/* ========= REDUX IMPORTS ========*/
import { RootState } from '../../redux/reducers';
import { actions as statusHistoryActions, DataCenterShape } from '../../ducks/statusHistory/statusHistory.index';
import { actions as uiActions, selectors as uiSelectors } from '../../ducks/ui/ui.index';
import { actions as authActions } from '../../ducks/authentication/authentication.index';
import { actions as alertActions } from '../../ducks/alerts/alerts.index';
import Confirmation from '../../components/Confirmation/Confirmation';



const styles = {
  appStyles: {
    paddingLeft  : 'var(--container-y-padding)',
    paddingRight : 'var(--container-y-padding)',
  },
  outerContainer: {
    minHeight : 'calc(100vh - 100px)'
  }
}

const App: React.FC = () => {
  const dispatch         = useDispatch();
  const isVisible        = usePageVisibility();
  const location         = useLocation()
  const navigate         = useNavigate();
  const windowDimensions = useWindowSize();
  const dcRef            = useRef("")
  const { t }            = useTranslation(["translation"]);

  const { uiState, statusHistoryState, auth, alerts } = useSelector(({ uiState, statusHistoryState, authState, alertsState }: RootState) => ({
    alerts: {
      disruptionBanner        : alertsState.disruptionBanner,
      activeServiceAdvisories : alertsState.activeServiceAdvisories,
      serviceAdvisoriesLoaded : alertsState.serviceAdvisoriesLoaded
    },
    uiState: {
      loading      : uiState.loading,
      pageHasFocus : uiState.pageHasFocus,
      browser      : uiState.browser,
      modalIsOpen  : uiState.modalIsOpen,
      applications : uiState.applications,
      isMobile     : uiState.isMobile,
      displayLocale: uiState.displayLocale,
      activeLocales: uiState.activeLocales
    },
    auth: {
      data_center  : authState.data_center,
      profile      : authState.profile,
      loggedIn     : authState.loggedIn,
      isAuthLoaded : authState.isAuthLoaded,
      error        : authState.error,
    },
    statusHistoryState: {
      dataCenters      : statusHistoryState.dataCenters,
      rows             : statusHistoryState.rows,
      messageTypes     : statusHistoryState.messageTypes,
      severities       : statusHistoryState.severities,
      activeDataCenter : statusHistoryState.activeDataCenter
    }
  }), shallowEqual)

  /**** effect for setting page focus ****/
  useEffect(() => {
    dispatch(uiActions.setPageFocus(isVisible))
  }, [dispatch, isVisible])

  useEffect(() => {
    dispatch(uiActions.setWindowDimensions(windowDimensions))
  }, [dispatch, windowDimensions])

  // runOnce will hold actions that should only be run once,
  // like getting/setting the browser type, os type, things that won't change in user's sessions
  // or things that wont change if a user switches from CO to PCO.
  const runOnce = useCallback(() => {
    dispatch(alertActions.requestDisruptionBanner());
    dispatch(statusHistoryActions.requestSeverities());
    dispatch(uiActions.requestActiveLocales());
    dispatch(statusHistoryActions.requestDataCenters());
    dispatch(uiActions.setBrowser(platform.name))
    dispatch(uiActions.setOperatingSystem(platform.os))
    dispatch(uiActions.setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  }, [dispatch])

  useEffect(() => {
    runOnce()
  },[runOnce])

  // pageLoad will request/load the data that is needed for the app
  // this function should hold actions that should be call on page load and when a user switches from CO to PCO or vice versa
  const pageLoad = useCallback(() => {
    if (auth.loggedIn){
      // pageLoad() will re-run each time a dependency changes, so to stop repetitive api calls for the same info
      // we can use a ref for the DC, if the dcRef matches the users DC defined in their profile, we have already
      // ran the actions below and do not need to run them again
      if (auth.data_center == 'us'){
        dispatch(authActions.setDataCenter('us2'));
      }
      if (auth.data_center && statusHistoryState.dataCenters.length > 0 && dcRef.current !== auth.data_center){
        statusHistoryState.dataCenters.some((dc:DataCenterShape) => {
          // statusHistoryState.dataCenters.some will loop through the array of
          // data centers UNTIL dc.profile_services_name === auth.profile.data_center
          if (dc.profile_services_name === auth.data_center){
            // active dc and status history need the system_name
            dispatch(statusHistoryActions.setActiveDataCenter(dc.system_name));
            dispatch(statusHistoryActions.requestStatusHistory(dc.system_name));
            dispatch(statusHistoryActions.requestP2Incidents(dc.system_name));
            // service advisory needs the legacy_name
            dispatch(alertActions.requestAllActiveServiceAdvisories(dc.legacy_name));
            // set the dcRef to the DC in the users profile so these actions will not fire off again
            dcRef.current = auth.data_center
            // return true so we do not loop through the rest of the statusHistoryState.dataCenters array
            return true
          }
          // return false so we keep looping through statusHistoryState.dataCenters
          return false
        })
      }
    } else {
      // the current user is NOT a PCO user, so fire off the actions to grab status history data for all data centers
      for (const dc of statusHistoryState.dataCenters){
        dispatch(statusHistoryActions.requestStatusHistory(dc.system_name));
      }
    }
  }, [dispatch, auth.data_center, auth.loggedIn, statusHistoryState.dataCenters])

  useEffect(() => {
    // run pageLoad once auth has finished loading
    if(auth.isAuthLoaded) {
      pageLoad()
    }
  }, [pageLoad, auth.isAuthLoaded])

  useEffect(() => {
    dispatch(statusHistoryActions.requestMessageTypes(uiSelectors.getActiveApplication(uiState.applications).system_name));
  }, [dispatch, uiState.applications])

  const activeApp = uiSelectors.getActiveApplication(uiState.applications);
  /* TODO This next line is a hack (part 1 of 2). We need to do this because Route components have trouble with #, but we need it in order to serve "old verification links"
  After we update the email templates with a new verification URL and wait a grace period, we can remove this hack. */

  useEffect(() => {
    if (location.hash.startsWith('#/OPI-') || location.hash.startsWith('#/TEST-')) {
      // This is for the incident details page specifically (landing links come from RSS feed)
      navigate('/details/?incident=' + location.hash.replace('#/', ''));
    } else if (location.hash.startsWith('#/')) {
      // This is for bookmarked routes that look like /#/us/
      navigate("/" + location.hash.replace('#/', ''));
    } else if (location.hash.startsWith('#')) {
      // This is for every other hash reroute (landings come from subscription emails)
      navigate("/" + location.hash.replace('#', ''));
    }
  }, [location.hash])
  

  // get incident_id URL.query_param to redirect to corresponding details page
  const queryParamIncidentId =  useQuery(location.search).get('incident');
  return (
    <>
      <div style={{ ...styles.outerContainer }}>
        <NavBar companyName={auth.loggedIn !== null && auth.profile.company_name} application={activeApp} setModal={uiActions.setModalIsOpen(true)} />
        <Container fluid style={{ ...styles.appStyles }}>
          <NetworkDetector />
          {
            uiState.modalIsOpen &&
            <AboutModal
              messageTypes = {statusHistoryState.messageTypes}
              severities   = {statusHistoryState.severities}
              application  = {activeApp}
            />
          }
          { location.pathname !== "/" && <BackButton application={activeApp} />}
          { auth.error
            && <Confirmation
                  type={'failure'}
                  errorMessage={[
                    t('containers.app.authError.message'),
                    // this back button is different from our BackButton component,
                    // this button will force reload the page to start with a fresh state
                    <Button key='profile-failed' variant="link" onClick={() => window.location.reload()}>{ t(`containers.app.authError.backLink.${activeApp.display_name}`) }</Button>
                  ]} 
               />
          }
          {
            !auth.isAuthLoaded ? <Confirmation type={'loading'} /> :
            !auth.error &&
            <Routes>
              {/* AUTHENTICATION */}
              <Route path='/authenticate/:querystring' element={<Authenticate/>}></Route>

              {/* STATUS HISTORY */}
              {/* We need to do this next hack since users may have routes like /#emea bookmarked to take them directly to the emea dc status history table.
              We only have to do this for the 4 DCs listed below, any new DC we add will not have to go in this array */}
              {["/north_america", "/us", "/us2", "/emea", "/eu2", '/china', '/public_sector'].map(path => (
                  <Route path={path} key={path} element={<Navigate
                    key  = {path}
                    // us to north_america map needs to be hard coded here. We can't wait for the DC api call to resolve
                    to   = {`/?data-center=${path === "/north_america" ? "us2" : path === "/us" ? "us2" : path === "/emea" ? "eu" : path === "/china" ? "cn" : path === "/public_sector" ? "usg" : path.replace("/", "")}`}
                  />}/>
                ))}
              <Route path='/' element={<StatusHistory/>}></Route>

              {/* SERVICE ADVISORIES */}
              <Route path='/service-advisories/:id' element={(auth.loggedIn || process.env.REACT_APP_BYPASS_PROTECTED_ROUTE) &&
                <ServiceAdvisoryDetails serviceAdvisories={alerts.activeServiceAdvisories} loaded={alerts.serviceAdvisoriesLoaded} displayLocale={uiState.displayLocale} />
              }/>

              {/* SUBSCRIPTIONS */}
              <Route path="/subscriptions/*" element={(auth.loggedIn || process.env.REACT_APP_BYPASS_PROTECTED_ROUTE) && <Subscriptions/>}/>

              {/* VERIFICATION */}
              <Route path={"/verify-me/:email/:verification_code"} element={<VerifyMe/>}/>
              {/* TODO This next line is a hack (part 2 of 2). We are reading the URL after the # has been truncated.
                This is to catch "old verification links". After we update the email templates and wait a grace period, these can and should be removed. */}
              <Route path='/preconfirmation/:email/:verification_code' element={<Navigate to={{ pathname: `/verify-me/${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}`}} />}/>

              {/* INCIDENT DETAILS */}
              {/*
                We are using the referral_url when a user navigates from Service History Dashboard,
                the easiest way to capture that url is during a Navigate and store it in the routers state.
                The url looks like pco/details/?referral_url=<someURL>/OPI-xxxxxx.
                After the redirect, the url looks like /details?incident-OPI-xxxxxx
              */}
              <Route path="/pco/details/:priority_type" element={<Navigate to={{ pathname: `/details/${location.pathname.split('/')[3]}/?incident=${queryParamIncidentId}`}}  />}/>
              {/* <Route path="/pco/details/:priority_type" element={<Navigate to='/details/:priority_type'  />}/> */}
              {/* REMOVE THIS NEXT LINE AFTER THE PROD DEPLOYMENT - This is for backwards compatibility */}
              <Route path={`/details/:priority_type/:dc/:service/:date`} element={<IncidentDetails/>}/>
              <Route path={`/details/:priority_type/:dc/:service`} element={<IncidentDetails/>}/>
              <Route path={`/details/:priority_type/:dc`} element={<IncidentDetails/>}/>
              <Route path={`/details/:priority_type`} element={<IncidentDetails/>}/>
              <Route path={`/details/`} element={<IncidentDetails/>}/>
              
              {/* The intention of this ProtectedRoute is to mirror "old" functionality when navigating directly to /pco */}
              <Route path="/pco/*" element={(auth.loggedIn || process.env.REACT_APP_BYPASS_PROTECTED_ROUTE) &&
                <Navigate to={{ pathname: `/` }} />
              }/>
              <Route element={<NotFound/>}/>
            </Routes>
          }
        </Container>
      </div>
      <Footer />
    </>
  );
}


// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  );
}