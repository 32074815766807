import { put, call, select } from "redux-saga/effects";
import * as Eff from 'redux-saga/effects'
import { getAuthFromRefresh, getAuthToken, getAuthProfile } from './authentication.api';
import { actions as authActions, ActionTypes as authActionTypes, defaultProfileExpiry } from './authentication.index';
import { actions as uiActions, } from '../ui/ui.index';
import { actions as errorActions } from '../errors/errors.index';
import { parseAuthCode, parseAuthDC } from './authentication.helper';
import { authRedirect, hasValidCookie } from "./authentication.func";
import { RootState } from "../../redux/reducers";

const takeEvery: any = Eff.takeEvery;

export function* fetchAuthFromRefresh() {
  try {
    // @ts-ignore
    const store = yield select( ( { authState }:RootState) => ({
      data_center : authState.data_center,
      auth_token  : authState.auth_token,
      refresh     : authState.refresh.refresh_token,
    }));

    // @ts-ignore
    const response  = yield call(getAuthFromRefresh, store.data_center, store.refresh);
    const data = response.data.token;

    yield put(authActions.setAuthToken(data.access_token));
    yield put(authActions.setRefreshFromToken(Buffer.from(JSON.stringify({refresh_token: data.refresh_token, data_center: store.data_center, refresh_expiry: defaultProfileExpiry})).toString('base64')));
  }
  catch(error) {
    // handle ui effects
    yield put(authActions.setAuthError(true));
    yield put(authActions.removeCookies());
    yield put(authActions.setLoggedIn(false));
    yield put(uiActions.setActiveApplication("Open"));
    yield put(authActions.setIsAuthLoaded(true))
    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchAuthToken(action:any) {
  try {
    yield put(authActions.setIsAuthLoaded(false));
    const authCode = parseAuthCode(action.payload);
    const authDC = parseAuthDC(action.payload);
    yield put(authActions.setDataCenter(authDC));
    // @ts-ignore

    const response  = yield call(getAuthToken, authDC, authCode);
    const data = response.data.token;
    yield put(authActions.setAuthToken(data.access_token));
    yield put(authActions.setRefresh({refresh_token: data.refresh_token, refresh_expiry: data.refresh_required_after, data_center: authDC}));
  }
  catch(error) {
    // handle ui effects
    yield put(authActions.setAuthError(true));
    yield put(authActions.removeCookies());
    yield put(authActions.setLoggedIn(false));
    yield put(uiActions.setActiveApplication("Open"));
    yield put(authActions.setIsAuthLoaded(true))

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchAuthProfile(action:any) {
  try {
    // @ts-ignore
    const response  = yield call(getAuthProfile, action.payload.data_center, action.payload.auth_token);
    yield put(authActions.setDataCenter(action.payload.data_center));
    yield put(authActions.setProfile(response.data.profile));
  }
  catch(error) {
    // handle ui effects
    yield put(authActions.removeCookies());
    yield put(authActions.setLoggedIn(false));
    yield put(uiActions.setActiveApplication("Open"));
    yield put(authActions.setAuthError(true));
    yield put(authActions.setIsAuthLoaded(true))

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* handleLogin() {
  try {
    // @ts-ignore
    const store = yield select( ( { authState }:RootState) => ({
      data_center : authState.data_center,
      auth_token  : authState.auth_token,
      refresh     : authState.refresh,
      loggedIn   : authState.loggedIn,
    }));
    if (store.loggedIn){
      return;
    }
    const isLoggedIn =  hasValidCookie(store.data_center, store.auth_token, store.refresh.refresh_token)
    if(!isLoggedIn){
      yield put(authActions.removeCookies())
      authRedirect();
    } else {
      yield put(uiActions.setActiveApplication('Personalized Open'))
      yield put(authActions.setLoggedIn(true))
      yield put(authActions.setIsAuthLoaded(true))
    }
  }
  catch(error) {
    // handle ui effects
    yield put(authActions.removeCookies());
    yield put(authActions.setLoggedIn(false));
    yield put(uiActions.setActiveApplication("Open"));
    yield put(authActions.setAuthError(true));
    yield put(authActions.setIsAuthLoaded(true))
    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* handleLogout() {
  try {
    yield put(authActions.setIsAuthLoaded(false))
    
    yield put(authActions.removeCookies());
    yield put(authActions.setLoggedIn(false));
    yield put(uiActions.setActiveApplication("Open"));
    // after logging out, navigate to the home page
    window.location.assign("/")
  }
  catch(error) {
    // handle ui effects
    yield put(authActions.removeCookies());
    yield put(authActions.setAuthError(true));
    yield put(authActions.setIsAuthLoaded(true))
    window.location.reload()
    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}


// watcher saga
export default function authenticationSagas() {
    return [
      takeEvery(authActionTypes.FETCH_AUTH_FROM_REFRESH, fetchAuthFromRefresh),
      takeEvery(authActionTypes.FETCH_AUTH_TOKEN, fetchAuthToken),
      takeEvery(authActionTypes.FETCH_AUTH_PROFILE, fetchAuthProfile),
      takeEvery(authActionTypes.HANDLE_LOGIN, handleLogin),
      takeEvery(authActionTypes.HANDLE_LOGOUT, handleLogout),
    ] 
}
